import revive_payload_client_K6Q4MBgX3I from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GoVagFQ6fS from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8BQ5P9dGPI from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_AlSCHPqvOS from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_BfERLl5FnR from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_VsNlvtaEAu from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.9.6_typescript@5.5.2_vue@3.4.15_typescript@5.5.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/lion/Documents/work/outwork/website/nuxt3-template/.nuxt/components.plugin.mjs";
import prefetch_client_EAPQXefTVS from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_QWoZT5iUT9 from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@nuxtjs+i18n@8.0.1_rollup@4.9.6_vue@3.4.15_typescript@5.5.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import colors_6lZ7UqBuWQ from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@nuxt+ui@2.13.0_nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint_uoa5q7jdbvddr5n74c64kxhxtq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_xoUv12O2Wu from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@4.9.6/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_yzUibyuKgD from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.9.6/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import view_transitions_client_tn4o7ENeyl from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_mgzmILW0zV from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@types+node@20.11.14_encoding@0.1.13_eslint@8.56.0_optionat_hbckazpxr6lq3v37ztyvhuohq4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_3Gob6JUQRa from "/Users/lion/Documents/work/outwork/website/nuxt3-template/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.9.6_typescript@5.5.2_vue@3_awpjndunrx25p3tj44zrtd653e/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_K6Q4MBgX3I,
  unhead_GoVagFQ6fS,
  router_8BQ5P9dGPI,
  payload_client_AlSCHPqvOS,
  check_outdated_build_client_BfERLl5FnR,
  plugin_vue3_VsNlvtaEAu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EAPQXefTVS,
  i18n_QWoZT5iUT9,
  colors_6lZ7UqBuWQ,
  plugin_client_xoUv12O2Wu,
  plugin_yzUibyuKgD,
  view_transitions_client_tn4o7ENeyl,
  chunk_reload_client_mgzmILW0zV,
  plugin_3Gob6JUQRa
]